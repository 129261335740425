<template>
  <div id="test8">
    <div class="crumbs">垃圾分类 / 传感器ID列表</div>
    <div class="center">
      <div class="table">
        <div class="center_top">
          <span>
            <el-form :inline="true" class="demo-form-inline">
              <el-form-item label="筛选">
                <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所属街镇">
                <el-select v-model="search_street_id" placeholder="请选择">
                  <el-option :label="'全部'" :value="''"> </el-option>
                  <el-option
                    v-for="streetItem in StreetList"
                    :label="streetItem.street_name"
                    :value="streetItem.street_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="搜索">
                <el-input
                  placeholder="请输入传感器ID"
                  v-model="keywords"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit" size="medium"
                  >查询</el-button
                >
              </el-form-item>
            </el-form>
          </span>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#F5F7FA', 'text-align': 'center' }"
        >
          <el-table-column prop="id" label="编号" width="50" :align="'center'">
          </el-table-column>
          <el-table-column prop="device_id" label="传感器ID" :align="'center'">
          </el-table-column>
          <el-table-column prop="c_time" label="创建时间" :align="'center'">
          </el-table-column>
          <el-table-column
            prop="update_time"
            label="更新时间"
            :align="'center'"
          >
          </el-table-column>
          <el-table-column prop="point_name" label="所属点位" :align="'center'">
          </el-table-column>
          <el-table-column prop="temperature" label="温度" :align="'center'">
          </el-table-column>

          <el-table-column label="电量" :align="'center'">
            <template slot-scope="scope">
              <div>
                <img
                  style="width: 40px"
                  v-if="scope.row.voltage == 100"
                  src="../assets/dl_100.png"
                  alt=""
                />
                <img
                  style="width: 40px"
                  v-else-if="scope.row.voltage >= 90 && scope.row.voltage < 100"
                  src="../assets/dl_90.png"
                  alt=""
                />
                <img
                  style="width: 40px"
                  v-else-if="scope.row.voltage >= 80 && scope.row.voltage < 90"
                  src="../assets/dl_80.png"
                  alt=""
                />
                <img
                  style="width: 40px"
                  v-else-if="scope.row.voltage >= 70 && scope.row.voltage < 80"
                  src="../assets/dl_70.png"
                  alt=""
                />
                <img
                  style="width: 40px"
                  v-else-if="scope.row.voltage >= 60 && scope.row.voltage < 70"
                  src="../assets/dl_60.png"
                  alt=""
                />
                <img
                  style="width: 40px"
                  v-else-if="scope.row.voltage >= 50 && scope.row.voltage < 60"
                  src="../assets/dl_50.png"
                  alt=""
                />
                <img
                  style="width: 40px"
                  v-else-if="scope.row.voltage >= 40 && scope.row.voltage < 50"
                  src="../assets/dl_40.png"
                  alt=""
                />
                <img
                  style="width: 40px"
                  v-else-if="scope.row.voltage >= 30 && scope.row.voltage < 40"
                  src="../assets/dl_30.png"
                  alt=""
                />
                <img
                  style="width: 40px"
                  v-else-if="scope.row.voltage >= 20 && scope.row.voltage < 30"
                  src="../assets/dl_20.png"
                  alt=""
                />
                <img
                  style="width: 40px"
                  v-else
                  src="../assets/dl_10.png"
                  alt=""
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="信号" :align="'center'">
            <template slot-scope="scope">
              <div>
                <img
                  style="width: 30px"
                  v-if="scope.row.signal >= 80"
                  src="../assets/xh_4.png"
                  alt=""
                />
                <img
                  style="width: 30px"
                  v-else-if="scope.row.signal >= 60 && scope.row.signal < 80"
                  src="../assets/xh_3.png"
                  alt=""
                />
                <img
                  style="width: 30px"
                  v-else-if="scope.row.signal >= 40 && scope.row.signal < 60"
                  src="../assets/xh_2.png"
                  alt=""
                />
                <img
                  style="width: 30px"
                  v-else-if="scope.row.signal >= 20 && scope.row.signal < 40"
                  src="../assets/xh_1.png"
                  alt=""
                />
                <img
                  v-else
                  style="width: 30px"
                  src="../assets/wuxinhao.png"
                  alt=""
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="是否已绑定地埋桶" :align="'center'">
            <template slot-scope="scope">
              <div>
                {{ scope.row.is_bind == 1 ? "已绑定" : "未绑定" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" :align="'center'">
          </el-table-column>
          <el-table-column label="操作" :align="'center'">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="barrelDeviceOperateShowFn(scope.row.id)"
                >编辑备注</el-button
              >
              <el-button type="text" @click="remove(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <span style="float: right; margin-top: 20px; margin-right: 20px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[100]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.length"
            background
          >
          </el-pagination>
        </span>
      </div>
    </div>
    <el-dialog title="编辑备注" :visible.sync="barrelDeviceOperateShow">
      <el-form label-width="100px">
        <el-form-item label="备注">
          <el-input placeholder="请输入备注" v-model="str"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="barrelDeviceOperateShow = false">取 消</el-button>
        <el-button type="primary" @click="barrelDeviceOperateFn"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getDeviceIdList,
  barrelDeviceOperate,
  getGrantStreetList,
} from "../request/api";
export default {
  name: "DemoGetvihiclelist",
  data() {
    return {
      tableData: [],
      page: 1, //当前页
      limit: 100, //一页展示几条
      keywords: "",
      str: "",
      barrelDeviceOperateShow: false,
      id: 0,
      options: [
        {
          value: 1,
          label: "全部",
        },
        {
          value: 2,
          label: "未绑定",
        },
        {
          value: 3,
          label: "已绑定",
        },
      ],
      value: 1,
      search_street_id: "",
      StreetList: [],
    };
  },
  mounted() {
    this.getList();
    getGrantStreetList().then((res) => {
      this.StreetList = res.data.list;
    });
  },
  methods: {
    onSubmit() {
      this.getList();
    },
    handleSizeChange(val) {
      this.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    async getList() {
      let res = await getDeviceIdList({
        type: this.value,
        keywords: this.keywords,
        street_id: this.search_street_id,
      });
      // res.data;
      this.tableData = res.data.map((item) => {
        if (item.device_type == 2) {
          return {
            ...item,
            voltage: item.dev_power,
            signal: item.signal < 30 ? 0 : item.signal,
            temperature: item.temperature + "℃",
          };
        } else return item;
      });
    },
    barrelDeviceOperateShowFn(id) {
      this.barrelDeviceOperateShow = true;
      this.id = id;
    },
    async barrelDeviceOperateFn() {
      let res = await barrelDeviceOperate({
        id: this.id,
        remark: this.str,
        type: 1,
      });
      // console.log(res);
      this.barrelDeviceOperateShow = false;
      this.getList();
    },
    remove(id) {
      this.id = id;
      this.$confirm("确认删除该设备？")
        .then(async () => {
          let res = await barrelDeviceOperate({
            id: this.id,
            type: 2,
          });
          if (res.code == 1) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
#test8 {
  width: 100%;
  height: 100vh;
}

.crumbs {
  padding: 5px 20px;
  background-color: rgb(240, 240, 240);
}

.center {
  width: 100vw;
  height: calc(100% - 31px);
  // background-color: #000;
  margin: auto;

  .center_top {
    padding-top: 10px;
    padding-bottom: 13px;
    height: 36px;
    display: flex;
    justify-content: center;

    ::v-deep .el-input__inner {
      width: 190px;
      height: 32px;
    }
  }

  .table {
    height: calc(100vh - 100px);
    overflow: auto;
    padding: 0 10px;
  }
}
</style>
